// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { addDoc, setDoc, collection, doc, getDoc, getDocs, getFirestore, query, where, deleteDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from 'uuid';

// import { getAnalytics } from "firebase/analytics";

// import { firebaseConfig as devConfig } from './firebaseConfig.dev';
// import { firebaseConfig as prodConfig } from './firebaseConfig.prod';
import { firebaseConfig as config } from "./firebaseConfig";

// const isProduction = process.env.NODE_ENV === 'production';
// const firebaseConfig = isProduction ? prodConfig : devConfig;


// Initialize Firebase

const app = initializeApp(config);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// const analytics = getAnalytics(app);


const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw new Error(err)
  }
};
const registerWithEmailAndPassword = async (name, email, password, role = 'user') => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    return await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      isAdmin: role === 'admin'
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};


const getAllCollection = async (path) => {
  try {
    return getDocs(collection(db, path))
      .then(res => {
        return res.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
      })

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

const getSingleDoc = async (path, id) => {
  try {
    const docRef = doc(db, path, id);
    const res = await getDoc(docRef);
    return {
      ...res.data(),
      id: res.id,
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

const updateSingleDoc = async (path, id, data) => {
  try {
    const docRef = doc(db, path, id);
    return await updateDoc(docRef, data);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}


const addToCollection = async (path, data) => {
  try {
    // make sure id is matching the id in the collection
    return await setDoc(doc(db, path, data.id), data);

    // return await addDoc(collection(db, path), data);

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

const removeFromCollection = async (path, id) => {
  try {
    return await deleteDoc(doc(db, path, id));
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}


const uploadFile = async (path, file) => {
  try {
    const fileExtension = file.name.split('.').pop();
    const name = `${v4()}.${fileExtension}`;
    const fileRef = ref(storage, `${path}/${name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    console.log({ file });

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (err) => reject(err),
        async () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            return resolve(downloadURL);
          });
        }
      );
    });




  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}




export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getAllCollection,
  getSingleDoc,
  addToCollection,
  removeFromCollection,
  updateSingleDoc,
  uploadFile
};
