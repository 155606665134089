import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Error404 from "./pages/Error404";
import { AdminLogin } from "./pages/AdminLogin";
import { UserContext } from "./contexts/UserContext";
import Dashboard from "./pages/Dashboard";
import Layout from "./layouts/DefaultLayout";
import SideBarLayout from "./layouts/SideBarLayout";
import Centers from "./pages/Centers";
import CentersMap from "./pages/CentersMap";
import LanguageSelector from "./components/LanguageSelector";
import SingleCenter from "./pages/SingleCenter";
import AdminProtect from "./routes/AdminProtect";
import CreateCenter from "./pages/CreateCenter";
import UpdateCenter from "./pages/UpdateCenter";
import Home from "./pages/Home";
import Comments from "./pages/Comments";
// import { registerWithEmailAndPassword } from "./firebase";


export default function App() {
  const { user } = useContext(UserContext);

  // useEffect(() => {
  // Create Admin User
  // (async () => {
  //   const user = await registerWithEmailAndPassword(
  //     "Admin",
  //     "admin@ouplonger.com",
  //     "admin@ouplonger.com",
  //     "admin"
  //   );
  // })();
  // }, []);

  return (
    <>
      <LanguageSelector />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* <Route index element={<Navigate replace to="/dashboard" />} /> */}

          <Route index element={<Home />} />
          {/* Dashboard */}
          <Route
            element={
              <AdminProtect isAdmin={user?.isAdmin}>
                <SideBarLayout />
              </AdminProtect>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="dashboard/centers-map" element={<CentersMap />} />
            <Route path="dashboard/centers" element={<Centers />} />
            <Route path="dashboard/centers/:id" element={<SingleCenter />} />
            <Route path="dashboard/centers/create" element={<CreateCenter />} />
            <Route
              path="dashboard/centers/:id/edit"
              element={<UpdateCenter />}
            />

            {/* Centers */}
            <Route path="dashboard/comments" element={<Comments />} />
          </Route>
          <Route path="portalaccess" element={<AdminLogin />} />

          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </>
  );
}
